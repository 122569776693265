<template>
  <div class="content">
    <div class="search">
      <span>酒店名称</span>
      <el-input v-model="search_hotelName" size="small" placeholder="请输入酒店名称"></el-input>
      <el-button type="primary" size="small">搜索</el-button>
    </div>
    <div class="hotelList">
      <el-card class="box-card" v-for="(item, index) in hotelList" :key="index">
        <div class="hotel_card" @click="HotelComment(item)">
          <div class="hotel_name">{{ item.name }}</div>
          <div class="grade">
            <div>
              <span>{{ item.score.toFixed(1) }}</span>
              <span>/5.0</span>
            </div>
            <div>
              <div>{{ item.reply_count }}</div>
              待回复点评
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <div class="pagination">
      <pagination :data="pageNation_data" @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </pagination>
    </div>
  </div>
</template>

<script>
import Pagination from '../../../components/PaginationPage/Pagination.vue'
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      search_hotelName: '',//搜索酒店名称

      hotelList: [],//酒店列表
      // 传递分页组件的数据
      pageNation_data: {
        total: 0,
        currentPage: 1,
        currentSize: 10,
        small: true
      },
    }
  },
  created() {
    this.getHotelList()

  },

  methods: {
    HotelComment(item) {
      sessionStorage.setItem('hotelDetails', JSON.stringify(item))
      // this.$router.push({ path: '/comment/Comment_detail' })
      let data = JSON.stringify(item)
      this.$router.push({ path: `/comment/Comment_detail?hotelData=${data}` })
      console.log('HotelComment', item);
    },
    // 获取有评论的酒店
    getHotelList() {
      let data = {
        type: 2,
        page: this.pageNation_data.currentPage,
        page_rows: this.pageNation_data.currentSize,
      }
      // console.log('获取评论列表data', data);
      this.my_request('/admin/comment', data, 'GET').then(res => {
        console.log('获取评论列表结果', res);
        let { code, data } = res.data
        if (200 == code) {
          this.hotelList = data.list
          this.pageNation_data.total = data.total
        }
      })
    },


    // 分页组件事件
    handleSizeChange(val) {
      // 处理每页数量改变的逻辑
      console.log(`每页 ${val} 条`);
      this.pageNation_data.currentSize = val
      this.getHotelList()
    },
    handleCurrentChange(val) {
      // 处理当前页码改变的逻辑
      console.log(`当前页: ${val}`);
      this.pageNation_data.currentPage = val
      this.getHotelList()
    },



  },
}
</script>

<style lang="scss" scoped>
.content {
  font-size: 16px;
  position: relative;
  height: 100%;

  .pagination {
    position: absolute;
    bottom: 10px;
    right: 0;
  }

  .search {
    // font-size: 14px;
    display: flex;
    align-items: center;

    .el-input {
      width: 320px;
      margin: 0 10px;
    }
  }


  .hotelList {
    display: flex;
    flex-wrap: wrap;

    .box-card {
      margin: 32px 20px 0 0;
      border-radius: 10px;
      width: 280px;
      height: 160px;

      .hotel_name {
        height: 70px;
        font-weight: bold;
      }

      .grade {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        div:nth-child(1) {
          color: #0486FE;

          span:nth-child(1) {
            font-size: 35px;
          }
        }

        div:nth-child(2) {
          font-size: 13px;
          text-align: center;

          div {
            color: red;
            font-weight: bold;
            font-size: 16px;

          }
        }
      }
    }

  }
}
</style>